// 1A1A1D (Primary)
// 4E4E50 (secondary)
// 6F2232
// 950740
// C3073F

// 1a1d24
const theme = {
  dark: {
    palette: {
      primary: {
        main: 'rgb(26, 26, 29,1)',
      },
      secondary: {
        light: '#347eff',
        main: 'rgb(58, 58, 62, 0.4)',
        // dark: will be calculated from palette.secondary.main,
        contrastText: '#ffcc00',
      },
      background: {
        default: '#f9f9f9',
        paper: '#ffffff',
        drawer: '#1a1f2f',
      },
      text: {
        primary: 'rgb(255,255,255,0.9)',
      },
    },
    typography: {
      fontFamily: 'sans-serif',
      fontSize: 16,
      fontWeight: 300,
      subtitle1:{
        fontFamily: 'sans-serif',
        fontWeight: 200,
        fontSize: '0.8rem',
        lineHeight: '1.6'
      },
      subtitle2: {
        fontWeight: 600,
        fontSize: '0.875rem',
      },
      h1: {
        fontFamily: 'sans-serif',
        fontWeight: 200,
        fontSize: '3rem',
        lineHeight: '1.6',
        sm: {
          fontSize: '2rem',
        },
        md: {
          fontSize: '3.5rem',
        }
      },
      p: {
        fontFamily: 'sans-serif',
        fontWeight: 300,
        fontSize: '1.3rem',
        lineHeight: '1.6',
        sm: {
          fontSize: '1.3rem',
        },
        md: {
          fontSize: '2.5rem',
        }
      },
      h2: {
        fontFamily: 'sans-serif',
        fontWeight: 400,
        fontSize: '2rem',
        lineHeight: '1.6',
        sm: {
          fontSize: '1.7rem',
        },
        md: {
          fontSize: '2.7rem',
        }
      },
      h6: {
        fontWeight: 300,
        fontSize: '1.1rem',
        sm: {
          fontSize: '0.8rem',
        },
      },
      body1: {
        fontWeight: 300,
        fontSize: '1.3rem',
        sm: {
          fontSize: '0.8rem',
        },
      },
      body2: {
        fontWeight: 300,
        fontSize: '1.0rem',
        sm: {
          fontSize: '0.8rem',
        },
      },
    //   font-size: 25px;
    // font-family: sans-serif;
    // line-height: 1.6;
    // font-weight: 300;
    },
    zIndex: {
      apiCreateMenu: 1250,
      operationDeleteUndo: 1600,
      overviewArrow: 1,
      goToSearch: 2,
    },
    custom: {

    },
  },
};
if (typeof module !== 'undefined') {
  module.exports = theme;
}
