import React, { useState } from 'react';

export const GlobalStateContext = React.createContext();

const GlobalContextProvider = ({ children }) => {
  const [data, setData] = useState(null);
  return (
    <GlobalStateContext.Provider value={{
      data,
      setData: (input) => setData(input),
    }}
    >
      {children}
    </GlobalStateContext.Provider>
  );
};

export default GlobalContextProvider;
